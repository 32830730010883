import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { Box, Flex } from '../Layout';

import media from '../../utils/media';
import feather from '../../utils/feather';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/instagram.jpeg';
import facebook from '../../assets/images/facebook.png';

const svgStyles = css`
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
  transition: color 0.15s ease-in;

  &:hover {
    text-decoration: none;
    box-shadow: none;
    opacity: 1;
    transition: opacity 0.15s ease-in;
  }
`;

const iconStyle = css`
  padding: 10px;
`;

const Contacts = () => (
  <Flex justifyContent="center">
    <a title="E-Mail address" href="mailto:seagooseberries@gmail.com"className={iconStyle}>
      {feather('mail', ['30', '30'], svgStyles)}
    </a>
    <a title="Twitter account" href="https://twitter.com/seagooseberries" target="_blank"className={iconStyle}>
      <img alt="twitter logo" src={twitter} height="30" />
    </a>
    <a title="Instagram account" href="https://www.instagram.com/seagooseberries/" target="_blank"className={iconStyle}>
      <img alt="instagram logo" src={instagram} height="30" />
    </a>
    <a title="Facebook account" href="https://www.facebook.com/seagooseberries/" target="_blank"className={iconStyle}>
      <img alt="facebook logo" src={facebook} height="30" />
    </a>
  </Flex>
);

Contacts.propTypes = {
  name: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  email: PropTypes.string
};

Contacts.defaultProps = {
  email: ''
};

export default Contacts;
