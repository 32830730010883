/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { css } from 'react-emotion';
import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import BackgroundImage from 'gatsby-background-image';
import Contacts from '../components/Contacts';

import gooseberry from '../assets/images/gooseberry-black.png';

const imgStyle = css`
  border-radius: 5px;
`;

const mainName = css`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const bottom = css`
  padding-bottom: 40px;
`;

const padding = css`
  padding-top: 50px;
  @media screen and (max-width: 600px) {
      padding-top: 100px;
    }
`;

const welcomeText = css`
  padding-bottom: 10px;
  padding-top: 0px;
`;

const listStyle = css`
  text-align: left;
`;

const Help = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
        margin="40px"
      >
        <Flex>
          <PaperBox
            width={[1, '80%', '60%']}
            ml="auto"
            mr="auto"
            mt="50px"
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <h1>Need help?</h1>
            <p>Want to send the Oracles a message or a question?</p>
            <Contacts></Contacts>
            <p>@seagooseberries</p>
            <p>If you have a Sea Gooseberry at home but you can't make it to <a href="/gathering">The Gathering</a>, please let us know asap. We can help to rehome it and ensure the celebrations go ahead. The magic will only work if all the Sea Gooseberries are present.</p>
            <p><img src={gooseberry} alt="Sea Gooseberry" width="200"/></p>
            <p>If you'd like a paper map to join the Quest, you can find one at:</p>
            <p className={bottom}>
              <ul className={listStyle}>
                <li>Folkestone Quarterhouse, Tontine St.</li>
                <li>Folkestone Library, 2 Grace Hill</li>
                <li>Sandgate Library, James Morris Court, Sandgate High Street</li>
                <li>Cheriton Library, 64 Cheriton High Street</li>
                <li>Hythe Library, 1 Stade Street, Hythe</li>
                <li>Romney Marsh Visitor Centre, Dymchurch Rd, New Romney</li>
                <li>Wood Avenue Library, 17 Wood Avenue, Folkestone</li>
              </ul>
            </p>
          </PaperBox>
        </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

export default Help;
